import { CustomCoursePromos } from "@/components/CustomCoursePromos";
import LinkButton from "@/components/LinkButton";
import Promo from "@/components/Promo";
import SpacePage from "@/components/SpacePage";
import SpacePic from "@/components/SpacePic";
import SpaceSection from "@/components/SpaceSection";
import { Heading } from "@chakra-ui/react";
import { PageProps } from "gatsby";
import React, { ReactElement } from "react";
import { EffectiveTypeScriptAbstract } from "./effective-typescript";
import { ReactWithTypeScriptAbstract } from "./react-with-typescript";

export function TypeScriptCoursePromos(): ReactElement {
  return (
    <Promo.Grid blocks={2}>
      <Promo.Block
        title="Effective TypeScript"
        image={<SpacePic name="saucer" />}
        button={
          <LinkButton variant="outline" to="/training/effective-typescript">
            Course Details
          </LinkButton>
        }
      >
        <EffectiveTypeScriptAbstract />
      </Promo.Block>
      <Promo.Block
        title="React with TypeScript"
        image={<SpacePic name="astronaut" />}
        button={
          <LinkButton variant="outline" to="/training/react-with-typescript">
            Course Details
          </LinkButton>
        }
      >
        <ReactWithTypeScriptAbstract />
      </Promo.Block>
    </Promo.Grid>
  );
}

export default function TypeScriptTrainingPage(
  _props: PageProps
): ReactElement {
  return (
    <SpacePage
      title="TypeScript Training"
      accent="orange"
      activeMenuItem="training"
    >
      <SpaceSection bg="beige" pt="16" pb="16">
        <Heading level="page" mb="8">
          TypeScript Training
        </Heading>
        <TypeScriptCoursePromos />
      </SpaceSection>
      <SpaceSection pt="24" pb="32" ct="lg">
        <CustomCoursePromos />
      </SpaceSection>
    </SpacePage>
  );
}
